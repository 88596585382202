import base64 from 'base-64'
import utf8 from 'utf8'
import i18n from '../i18n'

export const decodeToken = storedToken => {
  const [data] = storedToken.split('.')
  const token = data.replace(/_/g, '/').replace(/-/g, '+')
  const decodeToken = base64.decode(token)
  const json = utf8.decode(decodeToken)

  return JSON.parse(json).service
}

export const moneyFormat = amount => {
  return parseFloat(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const isEmpty = obj => {
  for (var _ in obj) {
    return false
  }
  return true
}

export const timeElapsed = time => {
  const sec = time % 60
  const min = Math.floor(time / 60)
  return {
    minutes: min,
    seconds: sec
  }
}

export const cardNumberFormat = value => {
  const trimmed = value.substring(0, 16)
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
  const onlyNumbers = trimmed.replace(/[^\d]/g, '')
  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter(group => !!group).join(' ')
  )
}

export const passwordMask = value => {
  return value.replace(/\d(?=\d{1})/g, '*')
}

export const cardBank = card => {
  const visaRgx = /^4/
  const masterCardRgx = /^5[1-5]/
  const isVisa = visaRgx.test(card)
  const isMasterCard = masterCardRgx.test(card)
  return isVisa && !isMasterCard
    ? 'visa'
    : !isVisa && isMasterCard
    ? 'mastercard'
    : null
}

export const cardMask = value => {
  return value.replace(/\d(?=\d{4})/g, '*')
}

export const b64toBlob = dataURI => {
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/jpeg' })
}

export const greetingMessage = () => {
  const today = new Date()
  const currentHour = today.getHours()

  if (currentHour < 12) {
    return i18n.t('greeting_message.good_morning')
  } else if (currentHour < 18) {
    return i18n.t('greeting_message.good_afternoon')
  } else {
    return i18n.t('greeting_message.good_evening')
  }
}
