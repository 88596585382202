import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
// Mobx
import { observer, inject } from 'mobx-react'
// Custom components
import Loader from 'components/molecules/loader'
import ScrollToTop from 'components/molecules/scrollTop'
// Access
const Auth = lazy(() => import('./auth'))
const Login = lazy(() => import('./login'))
// OTP
const OTPValidate = lazy(() => import('./otp'))
// Password
const Password = lazy(() => import('./password'))
const PasswordForgot = lazy(() => import('./password/forgot'))
// 404
const NotFound = lazy(() => import('./404'))
// Dashboard
const Dashboard = lazy(() => import('./dashboard'))

const AppRoutes = ({ authStore }) => {
  const { isLoggedIn, hasSession, cleanAccess } = authStore

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path='/' component={Auth} />
          <Route
            exact
            path='/login'
            render={() => {
              return isLoggedIn ? <Login /> : <Redirect to='/' />
            }}
          />
          <Route
            exact
            path='/dashboard'
            render={() => {
              return hasSession ? <Dashboard /> : <Redirect to='/login' />
            }}
          />
          <Route exact path='/validate' component={OTPValidate} />
          <Route exact path='/set-password' component={Password} />
          <Route exact path='/forgot-password' component={PasswordForgot} />
          <Route
            exact
            path='/logout'
            render={() => {
              cleanAccess()
              return <Redirect to='/' />
            }}
          />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  )
}

// export default AppRoutes
export default inject('authStore')(observer(AppRoutes))
