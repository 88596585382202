export const MINIMUM_DEBT_AMOUNT = 20000
export const SIMULATOR_COL_SIZES = {
  normal: {
    col1: 4,
    col2: 8
  },
  compact: {
    col1: 5,
    col2: 7
  }
}

export const AGENTS = {
  fer: {
    displayName: 'Fer',
    image: './assets/help/fer.png'
  },
  vane: {
    displayName: 'Vane',
    image: './assets/help/vane.png'
  }
}

export const STEPS_LIST = [
  {
    title: 'Tu identidad'
  },
  {
    title: 'Tu beneficiario'
  },
  {
    title: 'Tus finanzas'
  },
  {
    title: 'Tu buró'
  },
  {
    title: 'Tus deudas'
  },
  {
    title: 'Tu plan'
  },
  {
    title: 'Tu resumen'
  },
  {
    title: 'Tu contrato'
  },
  {
    title: 'Tu pago'
  }
]

export const OTP_STATUS = ['sms_sended', 'email_sended']

export const INVALID_STATUS = ['inactive', 'drop']
