import Api from 'api'

class UserService extends Api {
  setPassword = (input, id, token) => {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_CLIENTS_API_URL}/account/${id}`,
      data: {
        user: {
          password: input
        }
      },
      headers: {
        'Content-type': 'Application/json',
        Authorization: token
      }
    }

    return this.request(options)
  }
}

export default UserService
