import { createMuiTheme } from '@material-ui/core/styles'

// Override default theme
// https://material-ui.com/customization/default-theme/?expand-path=$.palette.primary
const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff'
    },
    primary: {
      light: '#4482D0', // blue_3
      dark: '#1463B1', // blue_2
      main: '#002E70', // blue_0
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#C4E0F7', // blue_5
      main: '#7CAEE5', // blue_4
      dark: '#115190', // blue_1
      contrastText: '#ffffff'
    },
    error: {
      light: '#FFEEEE', // red_3
      main: '#EA7A7A', // red_2
      dark: '#D85B5B', // red_1
      contrastText: '#ffffff'
    },
    warning: {
      light: '#FFE183', // yellow-2
      main: '#FEA45A', // orange_2
      dark: '#EA872D', // orange_1
      contrastText: '#ffffff'
    },
    success: {
      light: '#A4D868', // green_4
      main: '#82BB26', // green_3
      dark: '#147214', // green_1
      contrastText: '#ffffff'
    },
    text: {
      primary: '#646569',
      secondary: '#ffffff'
    },
    green: {
      light: '#F0F7E7', // green_2
      main: '#D0E6A7', // green_5
      dark: '#66992C' // green_2
    },
    blue: {
      light: '#E7F4FF'
    },
    gray: {
      light: '#F3F7FB',
      main: '#828282',
      dark: '#C9D1DA'
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: ['Rubik', 'sans-serif'].join(','),
    body1: {
      color: '#63656A', // gray_2
      fontSize: '1rem'
    },
    footer: {
      fontSize: '1rem'
    }
  },
  shadows: Array(25).fill('none')
})

theme.overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        background: `linear-gradient(247.35deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 98.96%);`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundColor: theme.palette.primary.light
      }
    }
  },
  MuiButton: {
    root: {
      borderRadius: 24,
      fontWeight: 700
    },
    textPrimary: {
      color: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.primary.dark,
        background: 'transparent',
        backgroundColor: 'none'
      },
      '&$disabled': {
        background: 'transparent',
        backgroundColor: 'none'
      }
    },
    containedPrimary: {
      '& .MuiButton-endIcon': {
        '& svg': {
          color: theme.palette.text.secondary
        }
      },
      '&:disabled': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main + '80'
      }
    },
    outlinedPrimary: {
      '&:disabled': {
        color: theme.palette.primary.main + '80',
        borderColor: theme.palette.primary.main + '80'
      }
    },
    endIcon: {
      marginLeft: '0.8rem'
    },
    containedSizeLarge: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.25rem'
      }
    }
  },
  MuiTypography: {
    h1: {
      fontSize: '2rem' // 32px
    },
    h2: {
      fontSize: '1.5rem' // 24px
    },
    h3: {
      fontSize: '1.375rem' // 22px
    },
    h4: {
      fontSize: '1.125rem' // 18px
    },
    h5: {
      fontSize: '1rem' // 16px
    }
  },
  MuiPaper: {
    root: {
      boxShadow: 'none'
    }
  },
  MuiContainer: {
    root: {
      [theme.breakpoints.up('lg')]: {
        paddingRight: '4rem',
        paddingLeft: '4rem'
      }
    }
  },
  MuiSwitch: {
    root: {
      height: 40,
      width: 54,
      left: '-8px'
    },
    track: {
      color: '#C4DFF7'
    },
    thumb: {
      width: 22,
      height: 22,
      color: '#004377'
    }
  },
  MuiRadio: {
    root: {
      color: theme.palette.gray.dark
    }
  },
  MuiInput: {
    input: {
      color: theme.palette.secondary.dark,
      backgroundColor: '#F2F9FF',
      borderRadius: '8px',
      padding: '15px 26px 15px'
    },
    underline: {
      '&:before': {
        borderBottom: 0,
        display: 'none'
      },
      '&:after': {
        borderBottom: 0,
        display: 'none'
      },
      '&$error': {
        '& input': {
          backgroundColor: theme.palette.error.light
        }
      }
    }
  },
  MuiFormHelperText: {
    root: {
      '&$error': {
        color: theme.palette.error.dark,
        marginTop: '0.5rem',
        marginLeft: '1.5625rem'
      }
    }
  },
  MuiAvatar: {
    colorDefault: {
      color: theme.palette.secondary.main
    }
  },
  MuiInputAdornment: {
    positionStart: {
      '& p': {
        color: theme.palette.secondary.dark
      }
    }
  },
  MuiDialog: {
    paper: {
      margin: 0,
      borderTopRightRadius: '1.6rem',
      borderTopLeftRadius: '1.6rem',
      borderBottomRightRadius: '0',
      borderBottomLeftRadius: '0',
      [theme.breakpoints.up('md')]: {
        borderRadius: '1rem'
      }
    },
    paperFullWidth: {
      width: '100%'
    },
    scrollPaper: {
      alignItems: 'flex-end',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center'
      }
    }
  },
  MuiDialogContent: {
    root: {
      padding: 0,
      '&:first-child': {
        paddingTop: 0
      }
    }
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgb(68 130 208 / 85%)'
    }
  },
  MuiCheckbox: {
    root: {
      color: theme.palette.primary.light
    },
    checked: {
      color: theme.palette.primary.light
    },
    colorPrimary: {
      '&.Mui-checked ': {
        color: theme.palette.primary.light
      }
    }
  },
  MuiInputLabel: {
    filled: {
      color: theme.palette.secondary.dark,
      fontWeight: 500,
      top: '-2px',
      transform: 'translate(25px, 20px) scale(1)',
      '&.MuiInputLabel-shrink ': {
        fontWeight: 'normal',
        lineHeight: '14px',
        fontSize: '14px',
        paddingLeft: '15px'
      }
    }
  },
  MuiFilledInput: {
    root: {
      borderRadius: '8px',
      maxHeight: '48px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      backgroundColor: '#F2F9FF',
      background: '#F2F9FF',
      '&:hover, &:focus, &.Mui-focus': {
        backgroundColor: '#F2F9FF',
        background: '#F2F9FF'
      },
      '&.Mui-error, &.Mui-error .MuiInputBase-input': {
        background: theme.palette.error.light
      }
    },
    input: {
      color: theme.palette.secondary.dark,
      background: '#F2F9FF',
      padding: '18px 25px 12px',
      borderRadius: '8px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px'
    },
    underline: {
      '&:before': {
        borderBottom: 0,
        display: 'none'
      },
      '&:after': {
        borderBottom: 0,
        display: 'none'
      }
    }
  },
  MuiFormControlLabel: {
    root: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    label: {
      fontSize: '0.75rem',
      paddingTop: '0.5625rem',
      '& strong': {
        color: theme.palette.secondary.main
      },
      '& a': {
        color: theme.palette.secondary.main,
        fontWeight: 600,
        textDecoration: 'none'
      }
    }
  },
  MuiFormLabel: {
    root: {
      fontSize: '1rem',
      '&.Mui-error': {
        color: '#DD6464'
      }
    }
  },

  MuiSelect: {
    icon: {
      color: theme.palette.primary.light
    }
  },
  MuiAccordion: {
    rounded: {
      borderRadius: '0.5rem',
      '&:first-child, &:last-child': {
        borderTopRightRadius: '0.5rem',
        borderTopLeftRadius: '0.5rem',
        borderBottomLeftRadius: '0.5rem',
        borderBottomRightRadius: '0.5rem'
      }
    },
    root: {
      '&$expanded': {
        marginTop: 0
      }
    }
  },
  MuiFormGroup: {
    root: {
      '& .$MuiFormControlLabel-root': {
        '& .$MuiFormControlLabel-label': {
          fontSize: '1rem',
          fontWeight: 600,
          color: theme.palette.primary.light
        }
      }
    }
  }
}

export default theme
