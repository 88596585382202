// Mobx
import { action, computed, decorate, observable, toJS } from 'mobx'
import { ignore } from 'mobx-sync'
// Moment
import moment from 'moment'
// Services
import AuthService from 'services/authService'
import OTPService from 'services/otpService'
import UserService from 'services/userService'
// Utils
import { decodeToken } from '../../utils/utils'
import { OTP_STATUS, INVALID_STATUS } from '../../utils/const'
// Validations
import { isValidEmail } from 'forms/validations'
const authService = new AuthService()
const otpService = new OTPService()
const userService = new UserService()
const INDEFINITE = 'indefinite'

class AuthStore {
  account = {}
  accessToken = {}
  accessExpiration = null
  userData = {}
  loading = false
  otp = {}

  get isLoggedIn () {
    const { id, active } = toJS(this.account)
    return id && active && this.accessExpiration
  }

  get hasSession () {
    const { status } = toJS(this.userData)
    return status && this.accessExpiration
  }

  get userNames () {
    const account = toJS(this.account)

    return account && account.profile && account.profile.names
      ? account.profile.names
      : ''
  }

  cleanAccess = () => {
    this.account = {}
    this.accessToken = {}
    this.accessExpiration = null
    this.userData = {}
    this.loading = false
    this.otp = {}
  }

  setAccessExpirationHour = () =>
    (this.accessExpiration = moment().add(1, 'hours'))

  setAccessExpirationIndefinite = () => (this.accessExpiration = INDEFINITE)

  sessionExpired = () => {
    if (this.accessExpiration === INDEFINITE || !this.accessExpiration) {
      return
    }

    const expiration = moment(this.accessExpiration)
    const isExpired = moment().isAfter(expiration)

    if (isExpired) {
      this.cleanAccess()
    }
  }

  login = async password => {
    try {
      this.loading = true
      const { input } = this.account
      const response = await authService.login(input, password)
      const { status, data } = response

      if (status === 200 && data && data.user && data.user.token) {
        this.userData = decodeToken(data.user.token)
        this.loading = false
        return { success: true }
      }
    } catch (error) {
      this.loading = false
      return { success: false }
    }
    this.loading = false
    return { success: false }
  }

  signIn = async ({ value, rememberSession }) => {
    try {
      this.loading = true
      const { status, data } = await authService.signIn(value)
      const { user } = data && data.token ? decodeToken(data.token) : {}

      if (status === 200 && user && user.active) {
        rememberSession
          ? this.setAccessExpirationIndefinite()
          : this.setAccessExpirationHour()
      }

      this.loading = false

      if (
        status === 200 &&
        user &&
        !user.active &&
        INVALID_STATUS.includes(user.status)
      ) {
        return { success: false, error: user.status }
      }

      if (status === 200 && data && user && data.token && user.active) {
        this.account = user
        this.accessToken = data.token
        return { success: true }
      } else if (
        status === 200 &&
        data &&
        data.uid &&
        OTP_STATUS.includes(data.status)
      ) {
        this.setOTP(data)
      }
      return { success: false }
    } catch (error) {
      this.loading = false
      return { success: false, error: error.response.data.message }
    }
  }

  setOTP = data => (this.otp = data)

  validateOTP = async code => {
    try {
      this.loading = true
      const { uid, receiver } = this.otp
      const response = await otpService.validate(uid, receiver, code)
      const { status, data } = response
      this.loading = false
      if (status === 200 && data && data.uid) {
        this.setOTP(response.data)
        this.account = decodeToken(response.data.token)
        this.accessToken = response.data.token
        return { success: true }
      }
      return { success: false }
    } catch (error) {
      this.loading = false
      if (error && error.response) this.setOTP(error.response.data)
      return { success: false }
    }
  }

  requestOTP = async input => {
    try {
      this.loading = true
      const response = await otpService.request(input)
      const { status, data } = response
      this.loading = false
      if (status === 200 && data && data.uid) {
        this.setOTP(response.data)
        return { success: true }
      }
      return { success: false }
    } catch (error) {
      this.loading = false
      return { success: false }
    }
  }

  clearOTP = () => (this.otp = {})

  get otpRequestMethod () {
    const otp = toJS(this.otp)
    return isValidEmail(otp.receiver) ? 'email' : 'phone'
  }

  savePassword = async input => {
    try {
      this.loading = true
      const { id } = this.account
      const response = await userService.setPassword(
        input,
        id,
        this.accessToken
      )
      const { status, data } = response
      this.loading = false
      if (status === 200 && data && data.user && data.user.active) {
        return { success: true }
      }
      return { success: false }
    } catch (error) {
      this.loading = false
      return { success: false, error: error.response.data.message }
    }
  }
}

decorate(AuthStore, {
  // Auth
  account: [observable],
  accessToken: [observable],
  loading: [observable, ignore],
  userData: [observable],
  signIn: action,
  login: action,
  clearSession: action,
  sessionExpired: action,
  userNames: computed,
  isLoggedIn: computed,
  // OTP
  otp: [observable, ignore],
  setOTP: action,
  validateOTP: action,
  otpRequestMethod: computed
})

export default new AuthStore()
