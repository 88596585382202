import Api from 'api'

class AuthService extends Api {
  signIn = input => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_CLIENTS_API_URL}/users/login`,
      data: { input }
    }

    return this.request(options)
  }

  login = (input, password) => {
    const data = { user: { input, password } }
    const url = `${process.env.REACT_APP_CLIENTS_API_URL}/users/auth`

    return this.post(url, data)
  }
}

export default AuthService
